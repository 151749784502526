/**  =====================
      Data Tables css start
==========================  **/

.table {
  td,
  th {
    border-top: 1px solid $theme-border;
    white-space: nowrap;
    padding: 1.05rem 0.75rem;
  }

  thead th {
    border-bottom: 1px solid $theme-border;
  }

  tbody + tbody {
    border-top: 2px solid $theme-border;
  }
}
// Border versions

.table-bordered {
  border: 1px solid $theme-border;

  td,
  th {
    border: 1px solid $theme-border;
  }
}
// Zebra-striping

.table-striped {
  tbody tr:nth-of-type(2n + 1) {
    background-color: transparentize($primary-color, 0.95);
  }
}
// Hover effect

.table-hover {
  tbody tr {
    &:hover {
      background-color: transparentize($primary-color, 0.95);
    }
  }
}
// Table backgrounds

.table {
  .thead-dark {
    th {
      color: $white;
      background-color: $dark-color;
      border-color: darken($dark-color, 10%);
    }
  }
}

.table-dark {
  color: $white;
  background-color: $dark-color;

  td,
  th,
  thead th {
    border-color: darken($dark-color, 10%);
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: darken($dark-color, 2%);
    }
  }

  &.table-hover {
    tbody tr {
      &:hover {
        background-color: darken($dark-color, 5%);
      }
    }
  }
}
// fixed header position

table.dataTable.fixedHeader-floating {
  top: 0 !important;
}

/**====== Data Tables css end ======**/
