/* Importing Bootstrap SCSS file. */
// @import "node_modules/bootstrap/scss/bootstrap";

// /* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

// /* Importing Bootstrap Icons SCSS file. */
// @import "node_modules/bootstrap-icons/font/bootstrap-icons";

// @import "node_modules/primeng/resources/themes/luna-blue/theme.css";  // PrimeNG theme
// @import "node_modules/primeng/resources/primeng.min.css";          // PrimeNG core styles
// @import "node_modules/primeicons/primeicons.css";     


/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import "node_modules/primeng/resources/themes/saga-blue/theme.css";  // PrimeNG theme
@import "node_modules/primeng/resources/primeng.min.css"; 
@import "node_modules/primeflex/primeflex.css";              // PrimeNG core styles
@import "node_modules/primeicons/primeicons.css";  
/* You can add global styles to this file, and also import other style files */

@import 'scss/variables';
/* fonts-icon */
@import 'scss/fonts/fontawesome/scss/fontawesome';
@import 'scss/fonts/feather/iconfont';
@import 'scss/general';
@import 'scss/generic';
@import 'scss/mixins/function';
/* important element */
@import 'scss/menu/menu-lite';
/* basic elements */
@import 'scss/theme-elements/theme-elements';
/* third party modules style*/
@import 'scss/plugins/plugins';
@import 'scss/custom';

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.custom-dropdown ul, .custom-dropdown ol {
  padding: 0 !important;
  margin: 0 !important; 
  padding-left: 0 !important; /* Optional: also remove margin if needed */
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
   


 

